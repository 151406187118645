export { appClass };
import Vue from "vue";
import App from "../App.vue";
import { router } from "../router/router";
import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import i18n from '../plugins/i18n';

// router.beforeEach((to, from, next) => {
//   if (!to.matched.length) {
//     console.log("route not found");
//   } else {
//     next();
//   }
// });
class appClass {
  constructor() {
    import(
      /* webpackChunkName: "configClass" */ /* webpackMode: "lazy" */ "./configClass"
    ).then(config => {
      this.config = new config.configClass();
      this.config.loadConfig();
    });
    eventBus.$on("configLoaded", this.loadVue);
  }

  loadVue() {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  }
}
