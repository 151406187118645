export { configClass };

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import { encryptDeviceInfo } from "@/utilities.js";
import { default as providerConfig, _firebase } from "../provider-config.js";

import { setNotificationsToLocalStorage, getDeepLink } from "./../utilities.js";

let counterFlag = 1;
class configClass {
  constructor() {
    this.availabilityList = [];
    this.page = 1;
    this.pageSize = 15;
    this.ISTTLACTIVE = false;

    const expiry = localStorage.getItem('config-expire');
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (!expiry || now.getTime() > expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      this.ISTTLACTIVE = false;
      
    }
  }



  loadConfig() {

   if (localStorage.getItem('appConfig') && this.ISTTLACTIVE) {
    this.startUpActions(JSON.parse(localStorage.getItem('appConfig')));
    return;
   }
    store
      .dispatch("getConfig")
      .then((response) => {
        const ttl = new Date().getTime() + ((5 * 60) * 1000);
        localStorage.setItem("config-expire", ttl);
        localStorage.setItem("appConfig", JSON.stringify(response));

        // Call the func
        this.startUpActions(response);
      })
      .catch((error) => {
        let localAppConfig = localStorage.getItem("appConfig");

        if (localAppConfig) {
          localAppConfig = JSON.parse(localAppConfig);

          if (Object.keys(localAppConfig).length > 0) {
            this.startUpActions(localAppConfig);
          }
        }

      });
  }


  loadDeckingConfig() {
    return new Promise ((resolve, reject) => {
      store.dispatch("actDeckingCallService").then((res) => {
        if (!res.data.success && counterFlag <= 2) {
          localStorage.removeItem("deviceToken");
          this.loadConfig()
          counterFlag++;
          return;
        }
        store
        .dispatch("getDeckingConfig", res.data.success)
        .then((response) => {
          localStorage.setItem("appDeckingConfig", JSON.stringify(response));
          resolve(true)
        })
        .catch((error) => {
         console.error("error", error);
         reject(false)
        });
      })



    })
  
  }
  initFirebaseConfig() {
    if (firebase.apps.length) return;

    let firebaseConfig = {
      appId: store.getters.FIRE_APP_ID,
      apiKey: store.getters.FIRE_API_KEY,
      authDomain: store.getters.FIRE_AUTH_DOMAIN,
      databaseURL: store.getters.FIRE_DATABASE_URL,
      projectId: store.getters.FIRE_PROJECT_ID,
      storageBucket: store.getters.FIRE_STORAGE_BUCKET,
      messagingSenderId: store.getters.FIRE_MESSAGING_SENDER_ID,
      measurementId: store.getters.FIRE_MEASUREMENT_ID,
    };

    let noorplayfirebaseConfig = {
      appId: store.getters.FIRE_APP_ID_4,
      apiKey: store.getters.FIRE_API_KEY_4,
      authDomain: store.getters.FIRE_AUTH_DOMAIN_4,
      databaseURL: store.getters.FIRE_DATABASE_URL_4,
      projectId: store.getters.FIRE_PROJECT_ID_4,
      storageBucket: store.getters.FIRE_STORAGE_BUCKET_4,
      messagingSenderId: store.getters.FIRE_MESSAGING_SENDER_ID_4,
      measurementId: store.getters.FIRE_MEASUREMENT_ID_4,
    };

    let fireBasePOC = {
      apiKey: "AIzaSyBJX2CCB-zyTZrMyYIrvnXIDt0hJKytcq4",
      authDomain: "vlive-poc.firebaseapp.com",
      databaseURL: "https://vlive-poc-default-rtdb.firebaseio.com",
      projectId: "vlive-poc",
      storageBucket: "vlive-poc.appspot.com",
      messagingSenderId: "244685937021",
      appId: "1:244685937021:web:df420ee79ff952967b9414",
      measurementId: "G-TEQT95PJYX",
    };
    // If firebase config is - Prepord
    if (_firebase === "preprod") {
      firebase.initializeApp(firebaseConfig);
    }
    // If firebase config is - PrepordPOC
    else if (_firebase === "preprodPOC") {
      firebase.initializeApp(fireBasePOC);
    }
    // If firebase config is - Net5 Prod
    else if (_firebase === "net5") {
      firebase.initializeApp(firebaseConfig);
    }
    // If firebase config is - NammaFlix Prod
    else if (_firebase === "nammaflix") {
      console.log("nammaflix");
      firebase.initializeApp(nammaflixfirebaseConfig);
    }
    // If firebase config is - Noorplay prod
    else if (_firebase === "noorplay") {
      firebase.initializeApp(noorplayfirebaseConfig);
    }

    // Init firebase messaging and subscribe to basic topics
    this.initFirebaseMessaging();
  }

  startUpActions(response) {
    store.commit("commitappconfig");
    store.commit("commitbaseurls", response.providerDetails.baseURLs);
    store.commit("commitextrapaths", response.providerDetails.extrapath);

    
    store.commit("commitsubscriberdetails");

    if (!localStorage.getItem("setDisplayLanguageCode")) {
      localStorage.setItem("setDisplayLanguageCode", 'eng');
    }

    providerConfig(response.providerDetails.providerId);
		// Fetch i18n Language From API
    const deviceToken = localStorage.getItem("deviceToken");

    const appconfigLocal = localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : ''
		if(!deviceToken) {
   	 encryptDeviceInfo().then((response) => {
      store
        .dispatch("deviceRegister", response)
        .then((response) => {
          // Load the Firebase Config
          this.initFirebaseConfig();

          if (response.success) {
            localStorage.setItem("deviceToken", response.success);

          
            // Fetch i18n Language From API
            const localLanguage = localStorage.getItem("setDisplayLanguageCode");

            if(!localLanguage) {
              this.fetchDisplayLanguage().then(data => {
                
                  if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                    this.loadDeckingConfig().then(() => {
                      eventBus.$emit("configLoaded");

                    });
                  } else {
                    eventBus.$emit("configLoaded");
                  }
                
              }).catch(err => {
                if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                  this.loadDeckingConfig().then(() => {
                    eventBus.$emit("configLoaded");

                  });
                } else {
                  eventBus.$emit("configLoaded");
                }
              });
            } else {
              if (appconfigLocal.featureEnabled.isDeckingEnabled) {
                this.loadDeckingConfig().then(() => {
                  eventBus.$emit("configLoaded");

                });
              } else {
                eventBus.$emit("configLoaded");
              }
            }

            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }
           

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();
          } else {
            eventBus.$emit("deviceRegister_error", response);

            eventBus.$emit("configLoaded");

            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();
          }
        })
        .catch((error) => {
          eventBus.$emit("deviceRegister_error", error);
          eventBus.$emit("configLoaded");
        });
    });
		} else {
      if (localStorage.getItem("appDeckingConfig") && this.ISTTLACTIVE ) {

        eventBus.$emit("configLoaded");
      } else {
         if (appconfigLocal.featureEnabled.isDeckingEnabled) {
          this.loadDeckingConfig().then(() => {
            eventBus.$emit("configLoaded");

          });
        } else {
          eventBus.$emit("configLoaded");
        }
      }
				this.initFirebaseConfig();
			 
    
            // Fetch country code
            this.fetchCurrentCountry();

            // Fetch language codes
            this.fetchAllLanguagesFullForm();

            // Fetch subscription list and store it - If User is already logged in at app launch
            if (store.getters.subscriberid) {
              this.fetchSubscriptionList();
            }

            // Setup service worker - Firebase and sw.js
            this.setupServiceWorkers();

				}

    import(/* webpackChunkName: "userClass" */ /* webpackMode: "lazy" */ "./userClass").then((user) => {
      this.user = new user.userClass();
    });
  }

  setupServiceWorkers() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((reg) => {
          navigator.serviceWorker.addEventListener("message", (message) => {
            navigator.serviceWorker.ready.then(function(registration) {
              // const deepLinkParamter = message.data["firebase-messaging-msg-data"].data.data;
              const deepLinkParamter = message.data.data.data;

              // const storageParameter = message.data["firebase-messaging-msg-data"].data;
              const storageParameter = message.data.data;

              // let data = message.data["firebase-messaging-msg-data"].data;
              let data = message.data.data;

              let notificationTitle = "";
              let notificationOptions = {};

              let link = getDeepLink(deepLinkParamter);

              if (data) {
                notificationTitle = data.title;
                notificationOptions = {
                  body: data.message,
                  icon: data.icon,
                  click_action: link,
                  image: data.picture,
                  data: {
                    action: link,
                  },
                };
              }
              registration.showNotification(notificationTitle, notificationOptions);

              setNotificationsToLocalStorage(storageParameter);

              eventBus.$emit("getNotificationsHeaderCount");
            });
          });
        })
        .catch((err) => console.error(`sw error:${err}`));
    }
  }

  actNewDeviceRegister() {
    return new Promise((resolve, reject) => {
      // localStorage.removeItem("random");
      encryptDeviceInfo()
        .then((response) => {
          store.dispatch("deviceRegister", response).then((response) => {
            store.commit("setToken", response.success);
            localStorage.setItem("subscriberToken", response.success);
            resolve(true);
          });
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  fetchAvailability() {
    let payload = {
      page: this.page,
      pagesize: this.pageSize,
    };
    store
      .dispatch("availabilityList", payload)
      .then((response) => {
        //storing total count in local variable.
        let totalCount = response.data.totalcount;
        //appeding availability response for the exising array.
        this.availabilityList = [...this.availabilityList, ...response.data.data];
        //commit the updated availability list.
        store.commit("setAvailabilityList", this.availabilityList);
        //checking if the lenght of availabilties less than total count. If true call availability list agian.
        if (this.availabilityList.length < totalCount) {
          this.page++;
          this.fetchAvailability();
        }
      })
      .catch((error) => console.error(error));
  }

  fetchSubscriptionList() {
    let payload = {
      subscriptionstatus: "ALL",
    };

    store
      .dispatch("listSubscription", payload)
      .then((response) => {
        if (!response.data.reason) {
          store.commit("setSubscriptionList", response.data.data);
        } else {
          store.commit("setSubscriptionList", []);
        }
      })
      .catch((error) => {
        store.commit("setSubscriptionList", [{}]);
      });
  }

  fetchPurchasedList() {
    store
      .dispatch("listPurchases")
      .then((response) => {
        if (!response.data.reason) {
          store.commit("setPurchasesList", response.data.data);
        }
      })
      .catch((error) => console.error(error));
  }

  fetchDisplayLanguage() {
    return new Promise((resolve, reject) => {
      store
        .dispatch("getDisplayLanguageAPI")
        .then((response) => {

          if(response.reason) {
            reject(false);
          } else if (!response.reason) {
            let languages = response.data;
            store.commit("setDisplayLanguage", languages);
  
            if (Array.isArray(languages) && languages.length) {
              let index = languages.findIndex((element) => {
                return element.isdefault === "YES";
              });
              // let code = null;
              if (index > -1) {
                let code = languages[index].language;
                code = languages[index].language;    
                code = code == "mar" ? 'mr' :  code;          
                localStorage.setItem("setDisplayLanguageCode", code);
                resolve(true);       
              } else {
                let code = languages[0].language;    
                code = code == "mar" ? 'mr' :  code;        
                localStorage.setItem("setDisplayLanguageCode", code);
                resolve(true);
                // code = languages[0].language;  
              }

              // code = code == "mar" ? 'mr' :  code;        
              // localStorage.setItem("setDisplayLanguageCode", code);
              // resolve(true);

            }
          }
        })
        .catch((error) => {
          reject(false);
          console.error(error)
        });
    })
  }

  fetchCurrentCountry() {
    fetch(`${store.getters.vSmsBaseUrl}subscriberv2/v1/getcountry`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        console.log("THE GET COUNTRY API RESPONSE -> ", response)

        if(!response.reason) {
          localStorage.setItem("currentUserCountry", JSON.stringify(response));
          store.commit("setCountry", response);
        } else {
          store.commit("setCountry", null);
        }
      })
      .catch((error) => console.error("Country Code", error));
  }

  checkCurrentLanguage() {
    const localLanguage = localStorage.getItem("setDisplayLanguageCode");

    if(!localLanguage) {
      this.fetchDisplayLanguage();
    }
  }

  fetchAllLanguagesFullForm() {
    store
      .dispatch("getLanguagesByCodes")
      .then((response) => {
        store.commit("setAllLanguageFullForm", response);
      })
      .catch((error) => {
      });
  }

  checkForMovieResponse() {
    let appconfig = JSON.parse(localStorage.getItem("appConfig"));
    let moviesSection = appconfig.screens.filter((el) => {
      return el.id === "MOVIES";
    });
    let screen = moviesSection[0].sections[0];

    let payload = {
      endpoint: screen.endpoint,
      params: screen.parameters,
    };

    store
      .dispatch("contentList", payload)
      .then((response) => {
        if (response.data.errorcode === 7109) {
          store.commit("setIsMovieEnabled", "No");
        } else {
          store.commit("setIsMovieEnabled", "Yes");
        }
      })
      .catch((error) => console.error(error));
  }

  initFirebaseMessaging() {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .requestPermission()
        .then(function() {
          return firebase.messaging().getToken();
        })
        .then(function(token) {
          localStorage.setItem("fcmToken", token);

          // Subscribe to the topic ("Broadcast")
          store
            .dispatch("fcmMessagingSubscribeToTopic", "BROADCAST")
            .then((data) => {
              console.log("user is subscibed to topic", "BROADCAST");
            })
            .catch((err) => {
              console.log("Not subscribed to ", "BROADCAST");
            });

          // Subscribe to the topic ("Device type")
          store
            .dispatch("fcmMessagingSubscribeToTopic", "PC")
            .then((data) => {
              console.log("user is subscibed to topic", "PC");
            })
            .catch((err) => {
              console.log("Not subscribed to ", "PC");
            });
        })
        .catch(function(error) {
          console.log("[App | initAWSMA | Notification | requestPermission | error ]", error);
        });
    }
  }
}
