let _fbId = null;
let _googleId = null;
let _clientKey = null;
let _providerId = null;
let _firebase = null;
let _projectName = null;

export default (providerId) => {
  console.log("providerId", providerId);
  switch (providerId) {

		case "cspacetv": //PROD
			_providerId = providerId;
			_clientKey = "8jqIlzT3oLprJJnQ";

			_fbId = "475071024764869";
			_googleId = "1088927012000-hnlaj9m3f5fn4qhamn5pk6bc96ig51qb.apps.googleusercontent.com";
			_firebase = "preprod";
			_projectName = "preprod";
			break;

      case "ksfdcpr": // BETA 
        _providerId = providerId;
        _clientKey = "Gn4ZoWPMnOyqnfOq";
        _fbId = "475071024764869";
        _googleId = "1088927012000-hnlaj9m3f5fn4qhamn5pk6bc96ig51qb.apps.googleusercontent.com";
        _firebase = "preprod";
        _projectName = "preprod";
        break;
    
  }
};

export { _providerId, _googleId, _fbId, _clientKey, _firebase, _projectName };
